<template>
<div class="vx-row widget-instance">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div class="flex flex-wrap justify-start mb-3">
          <img style="height:32px; border-radius: 3px"
            :src="widgetIcon"
            @error="setAltImgForIcon"
          >
          <h3 class="ml-2 mt-1">{{widgetName}}</h3>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-4">
            <span v-html="widgetDescription"></span>
          </div>
        </div>
        <div class="vx-row">
          <div v-for="(screenshot, index ) in screenshotUrls" :key="index" class="vx-col w-full md:w-1/4 lg:w-1/4 mb-6">
            <img
              class="responsive widget-instance-screenshot"
              :src="screenshot"
              @error="setAltImgForScreenshot"
            />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <!-- <travio-access-control requiredPermission="company_roles_edit"> -->
              <vs-button @click="addWidgetInstance" class="mr-4 sm:mb-0 mb-2">Add new widget</vs-button>
            <!-- </travio-access-control> -->
          </div>
        </div>
        <h4 class="mt-8">My {{widgetName}} Widgets</h4>
        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="widgetInstances"
          :gridOptions="gridOptions"
          rowSelection="single"
          :pagination="true"
          :context="context"
          :paginationPageSize="paginationPageSize"
          :domLayout="domLayout"
        >
        </ag-grid-vue>
        <!-- <vs-pagination
          :total="totalPages"
          :max="10"
          v-model="currentPage" /> -->
      </vx-card>

    </div>
  </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue"
import applicationWidgetStore from './applicationWidgetStore.js'
import TravioAccessControl from '@/components/travio-pro/TravioAccessControl.vue';
import CellRendererActions from './cell-renderers/CellRendererActions.vue'
import CellRendererInstallButton from './cell-renderers/CellRendererInstallButton.vue'
import { format } from 'date-fns'
import Vue from "vue"

export default {
  props: {
    applicationId: { required: true },
    widgetId: { required: false },
    widgetUrl: { required: true }
  },
  components: {
    AgGridVue,
    CellRendererInstallButton,
    CellRendererActions,
    TravioAccessControl
  },
  data () {
    return {
      widgetName: '',
      widgetDescription: '',
      widgetIcon: '',
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      // Makes the grid height adjust based on number of records
      domLayout: 'autoHeight',
      cellRendererComponents: {
        CellRendererActions,
        CellRendererInstallButton
      },
      widgetInstances: [],
      widgetInstallerTag: '',
      screenshotUrls: [],
      context: null // will be referenced by cell renderer so we can call methods from cell renderer to this
    }
  },
  computed: {
    activeAppName () {
      return this.$store.state.ActiveApplicationName
    },
    activeUser () {
      return this.$store.state.AppActiveUser
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: 'ID', field: 'widgetInstanceId', sortable: true },
      { field: 'applicationId', hide: true },
      { headerName: 'Widget Name', field: 'instanceName', sortable: true },
      { headerName: 'Installation', width: 260, sortable: false, suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererInstallButton) },
      // Supppress size to fit excludes column from auto resize if gridApi.sizeColumnsToFit() is called
      {
        headerName: 'Last Modified',
        field: 'lastModified',
        sortable: true,
        suppressSizeToFit: true,
        width: 220,
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUser.dateTimeFormat)
      },
      { headerName: 'Actions', width: 150, suppressSizeToFit: true, sortable: false, cellRendererFramework: Vue.extend(CellRendererActions) }
    ]

    this.context = { componentParent: this };
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }
  },
  mounted() {
    this.$vs.loading();
    this.$http.get(`api/widgets/apps/${this.applicationId}/${this.widgetUrl}`)
    .then(response => {
      this.widgetName = response.data.widgetName
      this.widgetDescription = response.data.widgetDescription
      this.widgetIcon = response.data.widgetIcon
      const body = response.data.widgetInstaller && response.data.widgetInstaller.resources && this.$_.find(response.data.widgetInstaller.resources.body, b => b.type === 'script')
      this.widgetInstallerTag = (body && body.contents) || ''

      this.widgetInstances = this.$_.map(response.data.widgets, w => {
        let regex = /{{(.*?)}}/g //<countdown-timer widgetid=\"{{ app.widgetid }}\" class=\"widgio-widget\"></countdown-timer>
        let installerScript = this.widgetInstallerTag && this.widgetInstallerTag.replace(regex, w.widgetInstanceId)
        return Object.assign({}, w, { applicationId: parseInt(this.applicationId), widgetUrl: this.widgetUrl, widgetInstaller: installerScript })
      })

      this.screenshotUrls = response.data.screenshotUrls
    }).catch(error => {
        this.$_notifyFailureByResponseData(error.response.data)
    }).finally(()=> this.$vs.loading.close());

    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  methods: {
    addWidgetInstance() {
      this.$router.push({ name: 'application-widget-add',
        params: {
          widgetUrl: this.widgetUrl,
          applicationId: this.applicationId
        }
      })
    },
    // Called by cell renderers via this.context
    reloadWidgetInstances(message) {
      this.$http.get(`api/widgets/apps/${this.applicationId}/${this.widgetUrl}`)
        .then(response => {
          this.widgetName = response.data.widgetName
          this.widgetInstances = this.$_.map(response.data.widgets, w => {
            return Object.assign({}, w, { applicationId: parseInt(this.applicationId), widgetUrl: this.widgetUrl })
          })
          this.screenshotUrls = response.data.screenshotUrls
          this.$_notifySuccess(message)
        })
        .catch(error => {
          console.locale(error)
        })

    },
    setAltImgForScreenshot (event) {
      const altText = encodeURIComponent('No image available')
      event.target.src = `https://dummyimage.com/1280X720/009687/ffffff.png&text=${altText}`
    },
    setAltImgForIcon (event) {
      const altText = this.widgetName && this.widgetName.charAt(0)
      event.target.src = `https://dummyimage.com/32x32/009687/ffffff.png&text=${altText}`
    }
  }
}
</script>

<style scoped lang="scss">
.app-heading {
  color: #636363
}
.widget-instance-screenshot{
  border-radius: 4px;
}
</style>
