<template>
    <div>
      
      <feather-icon title="Edit" v-if="true" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon title="Duplicate" icon="CopyIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="duplicateRecord" />
      <feather-icon title="Delete" v-if="true" icon="TrashIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
     <vs-prompt
        title="Delete Widget"
        accept-text="Delete"
        @cancel="closeDeleteDialog"
        @close="closeDeleteDialog"
        @accept="deleteWwidget"
        :active.sync="showDeleteDialog">

          <div class="vx-row mt-2 mb-4">
            <div class="vx-col">
              <span v-if="deleteAffectedUsersCount > 0">You are about to delete a role assigned to {{0}} user(s). Select a replacement role for these user(s).</span>
              <span v-if="deleteAffectedUsersCount == 0">Are you sure you want to delete this role?</span>
            </div>
          </div>
      </vs-prompt>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CellRendererActions',
  data () {
    return {
      showDeleteDialog: false,
      deleteAffectedUsersCount: 0,
      deleteHaveAffectedUsers: false,
      selectedReplacementRole: null
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    editRecord () {
      this.$router.push({ name: 'application-widget-edit', 
        params: { 
          widgetUrl: this.params.data.widgetUrl,
          applicationId: this.params.data.applicationId,
          widgetInstanceId: this.params.data.widgetInstanceId
        }
      })
    },
    duplicateRecord () {
      this.$vs.loading();
      this.$http.post(`api/widgets/apps/${this.params.data.applicationId}/instances/${this.params.data.widgetInstanceId}/copies`)
        .then(response => {
          // Call method in parent component
          this.params.context.componentParent 
            && this.params.context.componentParent.reloadWidgetInstances
            && this.params.context.componentParent.reloadWidgetInstances("Widget successfully copied")
        })
        .catch(error => this.$_notifyFailureByResponseData(error.response.data))
        .finally(() => this.$vs.loading.close())
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Delete widget ${this.params.data.instanceName}`,
        accept: this.deleteWwidget,
        acceptText: 'Delete'
      })
    },
    closeDeleteDialog () {
   
    },
    deleteWwidget () {
      this.$vs.loading();
      this.$http.delete(`api/widgets/apps/${this.params.data.applicationId}/instances/${this.params.data.widgetInstanceId}`)
        .then(response => {
          // Call method in parent component
          this.params.context.componentParent 
            && this.params.context.componentParent.reloadWidgetInstances
            && this.params.context.componentParent.reloadWidgetInstances("Widget successfully deleted")
        })
        .catch(error => this.$_notifyFailureByResponseData(error.response.data))
        .finally(() => this.$vs.loading.close())
    }
  }
}
</script>
